.ArrowDown {
    transform: rotate(90deg) !important;
}

.BackgroundColor {
    background-color: white !important;
    border-radius: 5px !important;
}

.Button {
    background-color: #FDE12D !important;
    height: 40px !important;
}

.ButtonGrid {
    margin-top: 30px
}

.CenterGrid {
    text-align: center !important;
}

.ChipFalse {
    background-color: #ff9e80 !important;
    border-radius: 3px !important;
    height: 24px !important;
    margin-bottom: 9px
}

.ChipTrue {
    background-color: #b9f6ca !important;
    border-radius: 3px !important;
    height: 24px !important;
    margin-bottom: 9px
}

.Divider {
    margin: 10px 0 23px 0px !important;
    width: 15%
}

.GreenBox {
    padding: 4px 0px 4px 6px !important;
    margin-right: 2px !important;
    background-color: rgba(96, 217, 55, 0.48)
}

.GridTop {
    padding-top: 50px !important;
    padding-bottom: 50px !important;
}

.GridTopMobile {
    padding-top: 50px !important;
    padding-bottom: 0px !important;
}

.MobileTypography {
    font-size: 100vw !important;
}

.SubscriptionError {
    color: red !important;
    display: block !important;
}

.Admara {
    font-family: Admara-Regular, Roboto, Helvetica, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
    /* font-size: 6vw !important; */
    /* font-size: 40px !important; */
}

.Chlakh {
    font-family: Chlakh-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Babybrooklynnregular {
    font-family: babybrooklynnregular-Medium, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Dangki {
    font-family: Dangki-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.JoyMaker {
    font-family: JoyMaker-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.MochaFrappuccinoBold {
    font-family: MochaFrappuccino-Bold, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.MochaFrappuccinoRegular {
    font-family: MochaFrappuccino-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Mamapapa {
    font-family: mamapapa-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Rote {
    font-family: Rote-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.TheQueen {
    font-family: TheQueen-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Hamline {
    font-family: Hamline-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Camellio {
    font-family: Camellio-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.AndalaScript {
    font-family: AndalaScript-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Sillingen {
    font-family: Sillingen-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Albertho {
    font-family: Albertho-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.GtHerina {
    font-family: GtHerina, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Lediscussioni {
    font-family: Lediscussioni-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Housttik {
    font-family: Housttik-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Dreamofspring {
    font-family: Dreamofspring-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.InflatableFlamingo {
    font-family: InflatableFlamingo-Reg, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Queenland {
    font-family: Queenland-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.JeanJingga {
    font-family: JeanJingga, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Sausages {
    font-family: Sausages-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Goodies {
    font-family: Goodies-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.LarquetteTypeface {
    font-family: LarquetteTypeface-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.YummyBurger {
    font-family: YummyBurger-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.TheArmory {
    font-family: TheArmory-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.RanchSalad {
    font-family: RanchSalad-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.RoundedBlock {
    font-family: RoundedBlock, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Hitman {
    font-family: Hitman-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Beach {
    font-family: Beach-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Harugha {
    font-family: Harugha-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.MotionTherapy {
    font-family: MotionTherapy-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.floristyScript {
    font-family: floristyScript-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Pearlye {
    font-family: Pearlye-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Arson {
    font-family: Arson-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Timber {
    font-family: Timber-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.CornerOneBold {
    font-family: CornerOne-Bold-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.CornerOne {
    font-family: CornerOne-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Woodstick {
    font-family: Woodstick-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Lumber {
    font-family: Lumber-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Courtside {
    font-family: Courtside-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Unbroken {
    font-family: Unbroken-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.FruitOfHeaven {
    font-family: FruitOfHeaven-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.SweetLetter {
    font-family: SweetLetter-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Hunter {
    font-family: Hunter, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Quirkle {
    font-family: Quirkle-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Childish {
    font-family: Childish-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.CandyLane {
    font-family: CandyLane-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.bastian {
    font-family: bastian-Regular, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.WishYou {
    font-family: WishYou, Roboto, Helvetica, Arial, serif !important;
    margin: 0 !important;
}

.Heulwen {
    font-family: Heulwen-Typeface, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Matthias {
    font-family: Matthias-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 60px !important;
}

.Quimbo {
    font-family: Quimbo-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 60px !important;
}

.Gracil {
    font-family: Gracil-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 70px !important;
}

.Sunflowergarden {
    font-family: SBSUNFLOWERgarden-Medium, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Jamberry {
    font-family: Jamberry-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 80px !important;
}

.InkLine {
    font-family: InkLine-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 70px !important;
}

.Arten {
    font-family: Arten-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.BaliSunriseScript {
    font-family: BaliSunriseScript-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.MonsterCookies {
    font-family: MonsterCookies-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Fiore {
    font-family: Fiore-Title, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.PALMTREE {
    font-family: PALMTREE-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 40px !important;
}

.StreuselKuchen {
    font-family: StreuselKuchen-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 80px !important;
}

.GreenTeratai {
    font-family: GreenTeratai-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Berry {
    font-family: Berry-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.OldBiker {
    font-family: OldBiker-OldBikerbase, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Gorillabeer {
    font-family: Gorillabeer-Gorillabeerbase, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FlowerForRosalline {
    font-family: FlowerForRosalline-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 60px !important;
}

.Oktoberfest {
    font-family: Oktoberfest-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 60px !important;
}

.Hitterlove {
    font-family: Hitterlove-Script, Arial, serif !important;
    font-weight: black !important;
    font-size: 100px !important;
}

.SouthAustralia {
    font-family: SouthAustralia-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 30px !important;
}

.RosellaScript {
    font-family: RosellaScript-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 70px !important;
}

.Heartland {
    font-family: Heartland-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 70px !important;
}

.HANDLER {
    font-family: HANDLER-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Drum {
    font-family: Drum-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.SanstuyBrush {
    font-family: SanstuyBrush-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 40px !important;
}

.Jullian {
    font-family: Jullian-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Fix {
    font-size: 60 !important;
}


/* Premium fonts */

.Gattelon {
    font-family: Gattelon, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Jelly_Gumie {
    font-family: Jelly_Gumie, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Kickback {
    font-family: Kickback, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Rollies {
    font-family: Rollies, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Stopover {
    font-family: Stopover, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Grassroots {
    font-family: FB-Grassroots, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.HeyMissy {
    font-family: HeyMissy-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.CandyBells {
    font-family: CandyBells-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Dontcry {
    font-family: Dontcry, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Reatnia {
    font-family: Reatnia, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Kisses {
    font-family: Kisses, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Fairy_Helbram {
    font-family: Fairy_Helbram, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Moonwalk {
    font-family: Moonwalk, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.The_Beastest {
    font-family: The_Beastest, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Yeloow_Stone {
    font-family: Yeloow_Stone, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.TonicWater {
    font-family: TonicWater-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.QuickNotes {
    font-family: QuickNotes-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FBBeachCamping {
    font-family: FBBeachCamping-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Srishti {
    font-family: Srishti, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Gumbies {
    font-family: Gumbies, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.QueenCassie {
    font-family: QueenCassie-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Rhandy {
    font-family: Rhandy, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Mildyves {
    font-family: Mildyves, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Armstrong {
    font-family: Armstrong, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Ralia {
    font-family: Ralia, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Aurellia {
    font-family: Aurellia, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Yearbook {
    font-family: Yearbook-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FBSunnyDays {
    font-family: FBSunnyDays-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FBIslandVacation {
    font-family: FBIslandVacation-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Sugar_Biscuits {
    font-family: Sugar Biscuits, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Another_Morning {
    font-family: Another Morning, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.ROMAN {
    font-family: ROMAN, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Poolside {
    font-family: Poolside, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FBSunscreen {
    font-family: FBSunscreen-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Monjitto {
    font-family: Monjitto, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.CornerMarket {
    font-family: CornerMarket, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.AngelDust {
    font-family: AngelDust-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FBMangoMojito {
    font-family: FBMangoMojito-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Carpenter {
    font-family: Carpenter, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Jousky {
    font-family: Jousky, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Bata {
    font-family: Bata, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Ammoysia {
    font-family: Ammoysia, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Helgoce {
    font-family: Helgoce, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FBWatermelonMimosa {
    font-family: FBWatermelonMimosa-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Melanie {
    font-family: Melanie, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Imaginable {
    font-family: Imaginable, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.StarMood {
    font-family: StarMood, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Goldenrods {
    font-family: Goldenrods, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.Beatrice {
    font-family: Beatrice, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.DoubleVanilla {
    font-family: DoubleVanilla-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}

.FBSunflowerFarm {
    font-family: FBSunflowerFarm-Regular, Arial, serif !important;
    font-weight: black !important;
    font-size: 50px !important;
}
.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

@font-face {
    font-family: 'Admara-Regular';
    src: local('Admara-Regular'), url(./fonts/Admara-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'babybrooklynnregular-Medium';
    src: local('babybrooklynnregular-Medium'), url(./fonts/babybrooklynnregular-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Chlakh-Regular';
    src: local('Chlakh-Regular'), url(./fonts/Chlakh-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Dangki-Regular';
    src: local('Dangki-Regular'), url(./fonts/Dangki-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'JoyMaker-Regular';
    src: local('JoyMaker-Regular'), url(./fonts/JoyMaker-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'MochaFrappuccino-Bold';
    src: local('MochaFrappuccino-Bold'), url(./fonts/MochaFrappuccino-Bold.ttf) format('truetype');
}

@font-face {
    font-family: 'MochaFrappuccino-Regular';
    src: local('MochaFrappuccino-Regular'), url(./fonts/MochaFrappuccino-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'mamapapa-Regular';
    src: local('mamapapa-Regular'), url(./fonts/mamapapa-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Rote-Regular';
    src: local('Rote-Regular'), url(./fonts/Rote-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'TheQueen-Regular';
    src: local('TheQueen-Regular'), url(./fonts/TheQueen-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Hamline-Regular';
    src: local('Hamline-Regular'), url(./fonts/Hamline-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Camellio-Regular';
    src: local('Camellio-Regular'), url(./fonts/Camellio-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'AndalaScript-Regular';
    src: local('AndalaScript-Regular'), url(./fonts/AndalaScript-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Sillingen-Regular';
    src: local('Sillingen-Regular'), url(./fonts/Sillingen-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Albertho-Regular';
    src: local('Albertho-Regular'), url(./fonts/Albertho-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'GtHerina';
    src: local('GtHerina'), url(./fonts/GtHerina.ttf) format('truetype');
}

@font-face {
    font-family: 'Lediscussioni-Regular';
    src: local('Lediscussioni-Regular'), url(./fonts/Lediscussioni-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Housttik-Regular';
    src: local('Housttik-Regular'), url(./fonts/Housttik-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Dreamofspring-Regular';
    src: local('Dreamofspring-Regular'), url(./fonts/Dreamofspring-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'InflatableFlamingo-Reg';
    src: local('InflatableFlamingo-Reg'), url(./fonts/InflatableFlamingo-Reg.ttf) format('truetype');
}

@font-face {
    font-family: 'Queenland-Regular';
    src: local('Queenland-Regular'), url(./fonts/Queenland-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'JeanJingga';
    src: local('JeanJingga'), url(./fonts/JeanJingga.ttf) format('truetype');
}

@font-face {
    font-family: 'Sausages-Regular';
    src: local('Sausages-Regular'), url(./fonts/Sausages-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Goodies-Regular';
    src: local('Goodies-Regular'), url(./fonts/Goodies-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'LarquetteTypeface-Regular';
    src: local('LarquetteTypeface-Regular'), url(./fonts/LarquetteTypeface-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'YummyBurger-Regular';
    src: local('YummyBurger-Regular'), url(./fonts/YummyBurger-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'TheArmory-Regular';
    src: local('TheArmory-Regular'), url(./fonts/TheArmory-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'RanchSalad-Regular';
    src: local('RanchSalad-Regular'), url(./fonts/RanchSalad-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'RoundedBlock';
    src: local('RoundedBlock'), url(./fonts/RoundedBlock.ttf) format('truetype');
}

@font-face {
    font-family: 'Hitman-Regular';
    src: local('Hitman-Regular'), url(./fonts/Hitman-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Beach-Regular';
    src: local('Beach-Regular'), url(./fonts/Beach-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Harugha-Regular';
    src: local('Harugha-Regular'), url(./fonts/Harugha-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'MotionTherapy-Regular';
    src: local('MotionTherapy-Regular'), url(./fonts/MotionTherapy-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'floristyScript-Regular';
    src: local('floristyScript-Regular'), url(./fonts/floristyScript-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Pearlye-Regular';
    src: local('Pearlye-Regular'), url(./fonts/Pearlye-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Arson-Regular';
    src: local('Arson-Regular'), url(./fonts/Arson-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Timber-Regular';
    src: local('Timber-Regular'), url(./fonts/Timber-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'CornerOne-Bold-Regular';
    src: local('CornerOne-Bold-Regular'), url(./fonts/CornerOne-Bold-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'CornerOne-Regular';
    src: local('CornerOne-Regular'), url(./fonts/CornerOne-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Woodstick-Regular';
    src: local('Woodstick-Regular'), url(./fonts/Woodstick-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Lumber-Regular';
    src: local('Lumber-Regular'), url(./fonts/Lumber-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Courtside-Regular';
    src: local('Courtside-Regular'), url(./fonts/Courtside-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Unbroken-Regular';
    src: local('Unbroken-Regular'), url(./fonts/Unbroken-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FruitOfHeaven-Regular';
    src: local('FruitOfHeaven-Regular'), url(./fonts/FruitOfHeaven-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'SweetLetter-Regular';
    src: local('SweetLetter-Regular'), url(./fonts/SweetLetter-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Hunter';
    src: local('Hunter'), url(./fonts/Hunter.ttf) format('truetype');
}

@font-face {
    font-family: 'Quirkle-Regular';
    src: local('Quirkle-Regular'), url(./fonts/Quirkle-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Childish-Regular';
    src: local('Childish-Regular'), url(./fonts/Childish-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'CandyLane-Regular';
    src: local('CandyLane-Regular'), url(./fonts/CandyLane-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'bastian-Regular';
    src: local('bastian-Regular'), url(./fonts/bastian-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'WishYou';
    src: local('WishYou'), url(./fonts/WishYou.ttf) format('truetype');
}

@font-face {
    font-family: 'Heulwen-Typeface';
    src: local('Heulwen-Typeface'), url(./fonts/Heulwen-Typeface.ttf) format('truetype');
}

@font-face {
    font-family: 'Matthias-Regular';
    src: local('Matthias-Regular'), url(./fonts/Matthias-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Quimbo-Regular';
    src: local('Quimbo-Regular'), url(./fonts/Quimbo-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Gracil-Regular';
    src: local('Gracil-Regular'), url(./fonts/Gracil-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'SBSUNFLOWERgarden-Medium';
    src: local('SBSUNFLOWERgarden-Medium'), url(./fonts/SBSUNFLOWERgarden-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'Jamberry-Regular';
    src: local('Jamberry-Regular'), url(./fonts/Jamberry-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'InkLine-Regular';
    src: local('InkLine-Regular'), url(./fonts/InkLine-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Arten-Regular';
    src: local('Arten-Regular'), url(./fonts/Arten-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'BaliSunriseScript-Regular';
    src: local('BaliSunriseScript-Regular'), url(./fonts/BaliSunriseScript-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'MonsterCookies-Regular';
    src: local('MonsterCookies-Regular'), url(./fonts/MonsterCookies-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Fiore-Title';
    src: local('Fiore-Title'), url(./fonts/Fiore-Title.ttf) format('truetype');
}

@font-face {
    font-family: 'PALMTREE-Regular';
    src: local('PALMTREE-Regular'), url(./fonts/PALMTREE-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'StreuselKuchen-Regular';
    src: local('StreuselKuchen-Regular'), url(./fonts/StreuselKuchen-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'GreenTeratai-Regular';
    src: local('GreenTeratai-Regular'), url(./fonts/GreenTeratai-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Berry-Regular';
    src: local('Berry-Regular'), url(./fonts/Berry-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'OldBiker-OldBikerbase';
    src: local('OldBiker-OldBikerbase'), url(./fonts/OldBiker-OldBikerbase.ttf) format('truetype');
}

@font-face {
    font-family: 'Gorillabeer-Gorillabeerbase';
    src: local('Gorillabeer-Gorillabeerbase'), url(./fonts/Gorillabeer-Gorillabeerbase.ttf) format('truetype');
}

@font-face {
    font-family: 'FlowerForRosalline-Regular';
    src: local('FlowerForRosalline-Regular'), url(./fonts/FlowerForRosalline-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Oktoberfest-Regular';
    src: local('Oktoberfest-Regular'), url(./fonts/Oktoberfest-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Hitterlove-Script';
    src: local('Hitterlove-Script'), url(./fonts/Hitterlove-Script.ttf) format('truetype');
}

@font-face {
    font-family: 'SouthAustralia-Regular';
    src: local('SouthAustralia-Regular'), url(./fonts/SouthAustralia-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'RosellaScript-Regular';
    src: local('RosellaScript-Regular'), url(./fonts/RosellaScript-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Heartland-Regular';
    src: local('Heartland-Regular'), url(./fonts/Heartland-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'KeenerFont-Medium';
    src: local('KeenerFont-Medium'), url(./fonts/KeenerFont-Medium.ttf) format('truetype');
}

@font-face {
    font-family: 'HANDLER-Regular';
    src: local('HANDLER-Regular'), url(./fonts/HANDLER-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Drum-Regular';
    src: local('Drum-Regular'), url(./fonts/Drum-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'SanstuyBrush-Regular';
    src: local('SanstuyBrush-Regular'), url(./fonts/SanstuyBrush-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Jullian-Regular';
    src: local('Jullian-Regular'), url(./fonts/Jullian-Regular.ttf) format('truetype');
}


/* Premium fonts */

@font-face {
    font-family: 'Gattelon';
    src: local('Gattelon'), url(./fonts_premium/Gattelon.ttf) format('truetype');
}

@font-face {
    font-family: 'Jelly_Gumie';
    src: local('Jelly_Gumie'), url(./fonts_premium/Jelly_Gumie.ttf) format('truetype');
}

@font-face {
    font-family: 'Kickback';
    src: local('Kickback'), url(./fonts_premium/Kickback.ttf) format('truetype');
}

@font-face {
    font-family: 'Rollies';
    src: local('Rollies'), url(./fonts_premium/Rollies.ttf) format('truetype');
}

@font-face {
    font-family: 'Stopover';
    src: local('Stopover'), url(./fonts_premium/Stopover.ttf) format('truetype');
}

@font-face {
    font-family: 'FB-Grassroots';
    src: local('FB-Grassroots'), url(./fonts_premium/FB-Grassroots.ttf) format('truetype');
}

@font-face {
    font-family: 'HeyMissy-Regular';
    src: local('HeyMissy-Regular'), url(./fonts_premium/HeyMissy-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'CandyBells-Regular';
    src: local('CandyBells-Regular'), url(./fonts_premium/CandyBells-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Dontcry';
    src: local('Dontcry'), url(./fonts_premium/Dontcry.ttf) format('truetype');
}

@font-face {
    font-family: 'Reatnia';
    src: local('Reatnia'), url(./fonts_premium/Reatnia.ttf) format('truetype');
}

@font-face {
    font-family: 'Kisses';
    src: local('Kisses'), url(./fonts_premium/Kisses.ttf) format('truetype');
}

@font-face {
    font-family: 'Fairy_Helbram';
    src: local('Fairy_Helbram'), url(./fonts_premium/Fairy_Helbram.ttf) format('truetype');
}

@font-face {
    font-family: 'Moonwalk';
    src: local('Moonwalk'), url(./fonts_premium/Moonwalk.otf) format('truetype');
}

@font-face {
    font-family: 'The_Beastest';
    src: local('The_Beastest'), url(./fonts_premium/The_Beastest.otf) format('opentype');
}

@font-face {
    font-family: 'Yeloow_Stone';
    src: local('Yeloow_Stone'), url(./fonts_premium/Yeloow_Stone.otf) format('opentype');
}

@font-face {
    font-family: 'TonicWater-Regular';
    src: local('TonicWater-Regular'), url(./fonts_premium/TonicWater-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'QuickNotes-Regular';
    src: local('QuickNotes-Regular'), url(./fonts_premium/QuickNotes-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FBBeachCamping-Regular';
    src: local('FBBeachCamping-Regular'), url(./fonts_premium/FBBeachCamping-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Srishti';
    src: local('Srishti'), url(./fonts_premium/Srishti.ttf) format('truetype');
}

@font-face {
    font-family: 'Gumbies';
    src: local('Gumbies'), url(./fonts_premium/Gumbies.ttf) format('truetype');
}

@font-face {
    font-family: 'QueenCassie-Regular';
    src: local('QueenCassie-Regular'), url(./fonts_premium/QueenCassie-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Rhandy';
    src: local('Rhandy'), url(./fonts_premium/Rhandy.ttf) format('truetype');
}

@font-face {
    font-family: 'Mildyves';
    src: local('Mildyves'), url(./fonts_premium/Mildyves.ttf) format('truetype');
}

@font-face {
    font-family: 'Armstrong';
    src: local('Armstrong'), url(./fonts_premium/Armstrong.ttf) format('truetype');
}

@font-face {
    font-family: 'Ralia';
    src: local('Ralia'), url(./fonts_premium/Ralia.ttf) format('truetype');
}

@font-face {
    font-family: 'Aurellia';
    src: local('Aurellia'), url(./fonts_premium/Aurellia.ttf) format('truetype');
}

@font-face {
    font-family: 'Yearbook-Regular';
    src: local('Yearbook-Regular'), url(./fonts_premium/Yearbook-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FBSunnyDays-Regular';
    src: local('FBSunnyDays-Regular'), url(./fonts_premium/FBSunnyDays-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FBIslandVacation-Regular';
    src: local('FBIslandVacation-Regular'), url(./fonts_premium/FBIslandVacation-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Sugar Biscuits';
    src: local('Sugar Biscuits'), url(./fonts_premium/Sugar_Biscuits.ttf) format('truetype');
}

@font-face {
    font-family: 'Another Morning';
    src: local('Another Morning'), url(./fonts_premium/Another_Morning.ttf) format('truetype');
}

@font-face {
    font-family: 'ROMAN';
    src: local('ROMAN'), url(./fonts_premium/ROMAN.ttf) format('truetype');
}

@font-face {
    font-family: 'Poolside';
    src: local('Poolside'), url(./fonts_premium/Poolside.ttf) format('truetype');
}

@font-face {
    font-family: 'FBSunscreen-Regular';
    src: local('FBSunscreen-Regular'), url(./fonts_premium/FBSunscreen-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Monjitto';
    src: local('Monjitto'), url(./fonts_premium/Monjitto.ttf) format('truetype');
}

@font-face {
    font-family: 'CornerMarket';
    src: local('CornerMarket'), url(./fonts_premium/CornerMarket.ttf) format('truetype');
}

@font-face {
    font-family: 'AngelDust-Regular';
    src: local('AngelDust-Regular'), url(./fonts_premium/AngelDust-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FBMangoMojito-Regular';
    src: local('FBMangoMojito-Regular'), url(./fonts_premium/FBMangoMojito-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Carpenter';
    src: local('Carpenter'), url(./fonts_premium/Carpenter.ttf) format('truetype');
}

@font-face {
    font-family: 'Jousky';
    src: local('Jousky'), url(./fonts_premium/Jousky.ttf) format('truetype');
}

@font-face {
    font-family: 'Bata';
    src: local('Bata'), url(./fonts_premium/Bata.ttf) format('truetype');
}

@font-face {
    font-family: 'Ammoysia';
    src: local('Ammoysia'), url(./fonts_premium/Ammoysia.ttf) format('truetype');
}

@font-face {
    font-family: 'Helgoce-Regular';
    src: local('Helgoce-Regular'), url(./fonts_premium/Helgoce-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FBWatermelonMimosa-Regular';
    src: local('FBWatermelonMimosa-Regular'), url(./fonts_premium/FBWatermelonMimosa-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'Melanie';
    src: local('Melanie'), url(./fonts_premium/Melanie.ttf) format('truetype');
}

@font-face {
    font-family: 'Imaginable';
    src: local('Imaginable'), url(./fonts_premium/Imaginable.ttf) format('truetype');
}

@font-face {
    font-family: 'StarMood';
    src: local('StarMood'), url(./fonts_premium/StarMood.ttf) format('truetype');
}

@font-face {
    font-family: 'Goldenrods';
    src: local('Goldenrods'), url(./fonts_premium/Goldenrods.ttf) format('truetype');
}

@font-face {
    font-family: 'Beatrice';
    src: local('Beatrice'), url(./fonts_premium/Beatrice.ttf) format('truetype');
}

@font-face {
    font-family: 'DoubleVanilla-Regular';
    src: local('DoubleVanilla-Regular'), url(./fonts_premium/DoubleVanilla-Regular.ttf) format('truetype');
}

@font-face {
    font-family: 'FBSunflowerFarm-Regular';
    src: local('FBSunflowerFarm-Regular'), url(./fonts_premium/FBSunflowerFarm-Regular.ttf) format('truetype');
}